import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React, { useEffect, useState } from 'react';
import { S } from './FrameCompanies.styles';
import { useGetAllFrameCompanies } from '../../../../traits/api/frameCompany/useGetAllFrameCompanies';
import { Pagination } from '../../../../components/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { FrameCompaniesTable } from './components/FrameCompaniesTable';
import { SearchInput } from '../../../../components/SearchInput';
import { MultiSelect } from '../../../../components/MultiSelect';
import { useGetTags } from '../../../../traits/api/frameCompany/useGetTags';
import { H5 } from '@ansvar-sakerhet/ui/src/typography/H5';
import { Select } from '../../../../components/Select';
import { useFindAllUsersFrameCompany } from '../../../../traits/api/frameCompany/useFindAllUsersFrameCompany';
import { Checkbox } from '../../../../components/Checkbox';
import { useGetFrameCompaniesStats } from '../../../../traits/api/frameCompany/useGetStats';


export const FrameCompanies: React.FC = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([] as string[]);
  const [responsible, setResponsible] = useState<string | null>(
    'all'
  );
  const [accepted, setAccepted] = useState<string | null>(
    'all'
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState<boolean>(true);
  
  const { data: frameCompanyUsers } = useFindAllUsersFrameCompany();
  const navigate = useNavigate();
  const PAGE_SIZE = 30;
  useEffect(() => {
    const page = searchParams.get('page');
    const search = searchParams.get('search');
    const selectedTags = searchParams.getAll('selectedTags');
    const responsible = searchParams.get('responsible');
    const accepted = searchParams.get('accepted');
    const active = searchParams.get('active');
    if (page) {
      console.log("set page", page);
      setPage(parseInt(page));
    }
    if (search) {
      console.log("set search", search);
      setSearch(search);
    }
    if (selectedTags) {
      console.log("set selectedTags", selectedTags);
      setSelectedTags(selectedTags.filter((tag) => tag !== ''));
    }
    if (responsible) {
      console.log("set responsible", responsible);
      setResponsible(responsible);
    }
    if (accepted) {
      console.log("set accepted", accepted);
      setAccepted(accepted);
    }
    if (active) {
      console.log("set active", active);
      setActive(active === 'true');
    }
  }, []);

  useEffect(() => {
    updateParams();
  }, [page, search, selectedTags, responsible, accepted, active]);

  useEffect(() => {
    setPage(0);
  }, [search, selectedTags, responsible, accepted, active]);

  const updateParams = () => {
    const obj = {
      page,
      search,
      selectedTags,
      responsible,
      accepted,
      active,
    }
    console.log(obj);
     const params = new URLSearchParams(obj as any);
     setSearchParams(params.toString());
  }

  const { data, isLoading, count } = useGetAllFrameCompanies(
    PAGE_SIZE,
    page * PAGE_SIZE,
    search,
    selectedTags,
    responsible,
    accepted,
    active,
  );

  const {data: stats} = useGetFrameCompaniesStats();

  const { data: tagList, isLoading: tagLoading } = useGetTags();
  if (isLoading || !data) {
    return (
      <div>
        <S.Flex $flexDir="row" $justify="between">
          <H3>Avtalsföretag</H3>
          <S.Button style={{maxWidth: 350}} onClick={() => navigate('/ramavtals-foretag/skapa')}>
            <S.Icon src="Plus" /> Nytt Företag
          </S.Button>
        </S.Flex>
        <S.Margin $top="m" />
        <SearchInput
          placeholder="Sök efter organisationsnummer eller namn"
          input={search}
          setInput={setSearch}
        />
        <S.Margin $top="m" />
        <Loading />
      </div>
    );
  }

  if (tagLoading || !tagList) {
    return <Loading />;
  }
  const lista = tagList as any;
  const tags = lista.map((tag: any) => ({
    tagId: tag.tagId,
    tagName: tag.name,
  }));
  const getPercent = (num: number, total: number) => {
    const percent =  (num / total) * 1000;
    return Math.round(percent)/10;

  }
  return (
    <div>
      <S.Flex $flexDir="row" $justify="between">
        <H3>Avtalsföretag</H3>
        <S.Button style={{maxWidth: 350}} onClick={() => navigate('/ramavtals-foretag/skapa')}>
          <S.Icon src="Plus" /> Nytt Företag
        </S.Button>
      </S.Flex>
      <S.Flex dir='row' $justify='space-between' style={{backgroundColor: 'white', width:'100%',justifyContent:'space-between', padding: '10px 20px', marginTop: '10px'}}>
        <S.H5>Antal aktiva företag: {stats?.numCompanies}</S.H5>
        <S.H5>Andel godkända företag: {(stats?.numCompanies ?? 0) > 0 ? getPercent((stats?.numActiveCompanies ?? 0), (stats?.numCompanies ?? 1)) : 0 }%</S.H5>
        <S.H5>Andel bakgrundskontrollerade: {(stats?.numCompanies ?? 0) > 0 ? getPercent((stats?.numBackground ?? 0) , (stats?.numCompanies ?? 1)) : 0 }%</S.H5>
      </S.Flex>
      <S.Margin $top="m" />
      <S.Flex $flexWrap="wrap" $gap={12} $justify={'space-between'} style={{justifyContent: 'space-between'}}>
      <div style={{ width: '49%' }}>
        <SearchInput
        placeholder="Sök efter organisationsnummer eller namn"
        input={search}
        setInput={setSearch}
      />
      </div>
       <div style={{ width: '49%' }}>
          <MultiSelect
            label=""
            placeholder='Välj taggar'

            style={{ width: '100%' }}
            options={tags.sort((a: any, b: any) => {
              return a.tagName.localeCompare(b.tagName);
            }).map((tag: any) => ({
              key: tag.tagId,
              label: tag.tagName,
            }))}
            chosenOptions={selectedTags.map((tag) => ({
              key: tag,
              label: tags.find((t: any) => t.tagId === tag)?.tagName ?? '',
            }))}
            setChosenOptions={(tags) =>
              setSelectedTags(tags.map((tag) => tag.key))
            }
          />
        </div>
      </S.Flex>
      
      <S.Margin $top="m" />
      <S.Flex $flexWrap="wrap" $gap={12} $justify={'space-between'}>
       
        <div style={{ height: '100%' }}>
          <H5>Filtrera efter ansvarig person</H5>
          <Select
            style={{ height: '100%' }}
            label=""
            value={responsible === null ? undefined : responsible}
            onChange={(e) => setResponsible(e.target.value)}
          >
            <option key={'all'} value={'all'}>
              Alla
            </option>
            <option key={'no_responsible'} value={'no_responsible'}>
              Ingen ansvarig
            </option>
            
            {frameCompanyUsers?.customerUsers.sort((a:any,b: any) => {
              console.log(a,b);
              return a.firstName.localeCompare(b.firstName);
            }).map((user: any) => (
              <option key={user.customerUserId} value={user.customerUserId}>
                {user.firstName} {user.lastName}
              </option>
            ))}
            
          </Select>
        </div>
        <div style={{ height: '100%' }}>
          <H5>Filtrera efter godkänd</H5>
          <Select
            style={{ height: '100%' }}
            label=""
            value={accepted === null ? undefined : accepted}
            onChange={(e) => setAccepted(e.target.value)}
          >
            <option key={'all'} value={'all'}>
              Inget filter
            </option>
            <option key={'accepted'} value={'accepted'}>
              Leverantör godkänd
            </option>
            <option key={'not_accepted'} value={'not_accepted'}>
              Leverantör ej godkänd
            </option>
          </Select>
        </div>
        
      </S.Flex>
      <Checkbox label="Visa inaktiva" onChange={(e) => {setActive(!e.target.checked)}} checked={!active} />

      <S.Margin $top="xl" />
      <S.FrameCompanies>
        <FrameCompaniesTable frameCompanies={data} />
      </S.FrameCompanies>
      <S.Margin $top="m" />
      <Pagination
        numberOfPages={Math.ceil(count / PAGE_SIZE)}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};
