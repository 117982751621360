import React from 'react';
import { Title } from '../redListedCompanies/components/Title';
import { S } from './IFrames.styles';
import { Link } from 'react-router-dom';

export const Iframes: React.FC = () => {
  return (
    <div>
      <Title />
      <S.Margin $bottom="m" />
      <S.Flex $gap={12}>
        <Link style={{ color: 'black', textDecoration: 'none', padding: 8 }} to={'/rodlistade-bolag'}>
          Ansvar & säkerhets rödlista
        </Link>
        <Link  to={'/rodlistat-sh'} style={{ backgroundColor: '#C8AB5D', color: 'black', padding: 8, textDecoration: 'none' }}>
          Svensk handels varningslista
        </Link>
      </S.Flex>
      <S.Margin $bottom="l" />
      <iframe
        width="100%"
        height="600px"
        src="https://www.svenskhandel.se/sakerhetscenter/varningslistan"
        title="Fi varningslista"
      ></iframe>
    </div>
  );
};
