import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Wrapper = styled.div`
  flex-grow: 1;
  z-index: 50;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Error = styled.div`
  padding: 4px;
`;

const SelectIcon = styled(UIElements.Icon) <{ $rotated: boolean }>`
  ${({ $rotated }) => ($rotated ? 'transform: rotate(180deg);' : '')}
  transition: transform 0.3s;
  height: 15px;
  margin-left: auto;
`;

const MultiSelect = styled.div`
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  height: 53px;
  overflow: hidden;
  padding-right: 0rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

const SelectBox = styled.div<{ $show: boolean }>`
  ${({ $show }) => ($show ? 'display: flex;' : 'display: none;')}
  position: absolute;
  width: 100%;

  max-height: 200px;
  overflow: auto;

  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
`;

const MultiOption = styled.div`
  width: 100%;
`;

const ChosenOption = styled.div`
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;

  background-color: #e6e2de;
`;

export const S = {
  ...UIElements,
  ChosenOption,
  SelectBox,
  SelectIcon,
  MultiOption,
  MultiSelect,
  Wrapper,
  Label,
  Error,
};
