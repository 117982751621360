import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const RiskAssessmentContainer = styled(UIElements.Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  gap: 10px;
`;

const RiskAssesmentInner = styled(UIElements.Flex)`
  flex-direction: row;
  width: 100%;
`;

const RiskSquare = styled(UIElements.Flex)<{ color: string }>`
  width: 100%;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  padding-right: ${({ theme }) => theme.spacing.s};
  padding-left: ${({ theme }) => theme.spacing.s};
`;

const StatsInner = styled(UIElements.Flex)`
  width: 100%;
  height: 700px;
  background-color: white;
  padding: 20px;
  flex-direction: column;

  @media (min-width: 1350px) {
    width: calc(50% - 5px);
  }
`;

export const S = {
  ...UIElements,
  StatsInner,
  RiskAssesmentInner,
  RiskAssessmentContainer,
  RiskSquare,
};
