import React from 'react';
import toast from 'react-hot-toast';

import { S } from './Toast.styles';

export const error = (message: string) => {
  toast.custom((i) => (
    <S.ErrorToast>
      <S.Flex $align="center" $gap={24}>
        <span>
          <S.H6>Något gick fel!</S.H6>
          <S.P>{message}</S.P>
        </span>

        <S.Icon
          src="Close"
          width={14}
          color="#b00000"
          onClick={() => toast.dismiss(i.id)}
        />
      </S.Flex>
    </S.ErrorToast>
  ));
};

export const success = (message: string) => {
  toast.custom((i) => (
    <S.SuccessToast>
      <S.Flex $align="center" $gap={12}>
        <span>
          <S.H6>Succé!</S.H6>
          <S.P>{message}</S.P>
        </span>

        <S.Icon
          src="Close"
          width={14}
          color="#6fa577"
          onClick={() => toast.dismiss(i.id)}
        />
      </S.Flex>
    </S.SuccessToast>
  ));
};

export const info = (message: string) => {
  toast.custom((i) => (
    <S.InfoToast>
      <S.Flex $align="center" $gap={24}>
        <span>
          <S.H6>Info</S.H6>
          <S.P>{message}</S.P>
        </span>

        <S.Icon
          src="Close"
          width={14}
          color="#1890ff"
          onClick={() => toast.dismiss(i.id)}
        />
      </S.Flex>
    </S.InfoToast>
  ));
};

export const warning = (message: string) => {
  toast.custom((i) => (
    <S.WarningToast>
      <S.Flex $align="center" $gap={24}>
        <span>
          <S.H6>Varning!</S.H6>
          <S.P>{message}</S.P>
        </span>

        <S.Icon
          src="Close"
          width={14}
          color="#faad14"
          onClick={() => toast.dismiss(i.id)}
        />
      </S.Flex>
    </S.WarningToast>
  ));
};
