import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const StatsInner = styled(UIElements.Flex)`
  width: 100%;
  background-color: white;
  padding: 20px;
  height: 700px;
  flex-direction: column;

  @media (min-width: 1350px) {
    width: calc(50% - 5px);
  }
`;

const Box = styled.div`
  width: 100%;
`;

const ColorBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1.5px solid #d3d3d3;
  border-radius: 20%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: center;
  vertical-align: middle;
`;

export const S = { ...UIElements, StatsInner, Box, ColorBox };
