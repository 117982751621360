import React from 'react';
import { S } from './ProjectStatisticsTable.style';
import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { BarChart, ChartsReferenceLine } from '@mui/x-charts';
import { useProjectStatisticsTableData } from './hooks/useProjectStatisticsTableData';

export const ProjectStatisticsTable = ({
  statistics,
}: {
  statistics: {
    data: FindAllControlStatisticsResponse;
    clientId: string;
    projectName: string;
  }[];
}) => {
  const { labelX, dataX1, dataX2, dataX3, dataX4, dataX6, dataX7, dataX8, dataX10 } =
    useProjectStatisticsTableData(statistics);

  if (statistics.length === 0) {
    return null;
  }

  return (
    <S.Flex $flexWrap="wrap" $justify={'between'} $gap="10px">
      {showBarChart(
        dataX1,
        labelX,
        'Kontrollerat och inloggade i elektronisk personalliggare (EPL)'
      )}
      {showBarChart(dataX2, labelX, 'Kontrollerade, ej inloggade')}
      {showBarChart(
        dataX3,
        labelX,
        'Inloggade i elektronisk personalliggare (EPL), ej funna'
      )}
      {showBarChart(dataX4, labelX, 'Avvikelser mot ID06 regelverk')}
      {/* {showBarChart(dataX5, labelX, 'Safe Construction Training saknas')} */}
      {showBarChart(dataX6, labelX, 'Avsaknad av giltig legitimation')}
      {showBarChart(
        dataX7,
        labelX,
        'Personlig skyddsutrustning, avvikelse mot Arbetsmiljöverkets säkerhetsföreskrifter	'
      )}
      {showBarChart(
        dataX8,
        labelX,
        'Upphålls- och arbetstillstånd saknas (AT/UT/PUT/AT-UND)*',
        '*Beräknas på hur många kontroller där vi hittat minst en person utan tillstånd'
      )}
      {showBarChart(
        dataX10,
        labelX.slice(0, labelX.length - 1),
        'Förgodkända UE'
      )}
    </S.Flex>
  );
};

const showBarChart = (
  data: number[],
  labelX: string[],
  title: string,
  description?: string
) => {
  const countryVal = data[data.length - 1];

  return (
    <S.ShowBarChartContainer>
      <S.H6 style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>{title}</S.H6>
      <S.B4>{description}</S.B4>
      <BarChart
        series={[{ data, valueFormatter: (v) => `${v}%` }]}
        layout="horizontal"
        yAxis={[
          {
            tickLabelStyle: {
              angle: -45,
              textAnchor: 'end',
            },
            scaleType: 'band',
            data: labelX,
            colorMap: {
              type: 'ordinal',
              colors: data.map((d, i) =>
                i === data.length - 1 ? '#C8AB5D' : '#EECC71'
              ),
            },
          },
        ]}
        margin={{ left: 160 }}
        width={500}
        height={250}
        xAxis={[{ label: 'Andel (%)', min: 0, max: 100 }]}
      >
        <ChartsReferenceLine
          x={countryVal}
          labelAlign="end"
          lineStyle={{
            strokeDasharray: '10 5',
          }}
        />
      </BarChart>
    </S.ShowBarChartContainer>
  );
};
